.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.bg-primary {
  background: #3db16b !important;
  color: white !important;
}

.bg-primary-bright {
  background: #c0c5e4 !important;
}

.bg-primary-gradient {
  background: -webkit-linear-gradient(220deg, #3db16b, #65d993) !important;
  background: -moz- oldlinear-gradient(220deg, #3db16b, #65d993) !important;
  background: -o-linear-gradient(220deg, #3db16b, #65d993) !important;
  background: linear-gradient(230deg, #3db16b, #65d993) !important;
  color: white !important;
}

.bg-info {
  background: #00bcd4 !important;
  color: white !important;
}

.bg-info-bright {
  background: #e1efff !important;
}

.bg-info-gradient {
  background: -webkit-linear-gradient(220deg, #00bcd4, #28e4fc) !important;
  background: -moz- oldlinear-gradient(220deg, #00bcd4, #28e4fc) !important;
  background: -o-linear-gradient(220deg, #00bcd4, #28e4fc) !important;
  background: linear-gradient(230deg, #00bcd4, #28e4fc) !important;
  color: white !important;
}

.bg-secondary {
  background: dimgray !important;
  color: white !important;
}

.bg-secondary-bright {
  background: #ededed !important;
}

.bg-secondary-gradient {
  background: -webkit-linear-gradient(220deg, dimgray, #919191) !important;
  background: -moz- oldlinear-gradient(220deg, dimgray, #919191) !important;
  background: -o-linear-gradient(220deg, dimgray, #919191) !important;
  background: linear-gradient(230deg, dimgray, #919191) !important;
  color: white !important;
}

.bg-success {
  background: #0abb87 !important;
  color: white !important;
}

.bg-success-bright {
  background: #cef5ea !important;
}

.bg-success-gradient {
  background: -webkit-linear-gradient(220deg, #0abb87, #32e3af) !important;
  background: -moz- oldlinear-gradient(220deg, #0abb87, #32e3af) !important;
  background: -o-linear-gradient(220deg, #0abb87, #32e3af) !important;
  background: linear-gradient(230deg, #0abb87, #32e3af) !important;
  color: white !important;
}

.bg-danger {
  background: #ea4141 !important;
  color: white !important;
}

.bg-danger-bright {
  background: #fcd0df !important;
}

.bg-danger-gradient {
  background: -webkit-linear-gradient(220deg, #ea4141, #ff6969) !important;
  background: -moz- oldlinear-gradient(220deg, #ea4141, #ff6969) !important;
  background: -o-linear-gradient(220deg, #ea4141, #ff6969) !important;
  background: linear-gradient(230deg, #ea4141, #ff6969) !important;
  color: white !important;
}

.bg-danger-light {
  background: #ffd7d7 !important;
}

.bg-warning {
  background: #ff9d36 !important;
  color: white !important;
}

.bg-warning-bright {
  background: #ffe6cc !important;
}

.bg-warning-gradient {
  background: -webkit-linear-gradient(220deg, #ff9d36, #ffc55e) !important;
  background: -moz- oldlinear-gradient(220deg, #ff9d36, #ffc55e) !important;
  background: -o-linear-gradient(220deg, #ff9d36, #ffc55e) !important;
  background: linear-gradient(230deg, #ff9d36, #ffc55e) !important;
  color: white !important;
}

.bg-light {
  background: #e6e6e6 !important;
}

.bg-dark {
  background: #3a3f51 !important;
  color: white !important;
}

.bg-dark-bright {
  background: #d4d5d8 !important;
}

.bg-dark-gradient {
  background: -webkit-linear-gradient(220deg, #3a3f51, #585d6f) !important;
  background: -moz- oldlinear-gradient(220deg, #3a3f51, #585d6f) !important;
  background: -o-linear-gradient(220deg, #3a3f51, #585d6f) !important;
  background: linear-gradient(230deg, #3a3f51, #585d6f) !important;
  color: white !important;
}

.bg-facebook {
  background: #3b5998 !important;
  color: white !important;
}

.bg-twitter {
  background: #55acee !important;
  color: white !important;
}

.bg-linkedin {
  background: #0077b5 !important;
  color: white !important;
}

.bg-whatsapp {
  background: #43d854 !important;
  color: white !important;
}

.bg-instagram {
  background: #3f729b !important;
  color: white !important;
}

.bg-dribbble {
  background: #ea4c89 !important;
  color: white !important;
}

.bg-google {
  background: #db4437 !important;
  color: white !important;
}

.bg-youtube {
  background: #cd201f !important;
  color: white !important;
}

.text-primary {
  color: #3db16b !important;
}

.text-info {
  color: #00bcd4 !important;
}

.text-success {
  color: #0abb87 !important;
}

.text-danger {
  color: #ea4141 !important;
}

.text-warning {
  color: #ff9d36 !important;
}

.text-light {
  color: #e6e6e6 !important;
}

.text-facebook {
  color: #3b5998 !important;
}

.text-twitter {
  color: #55acee !important;
}

.text-google {
  color: #db4437 !important;
}

.text-linkedin {
  color: #0077b5 !important;
}

.text-instagram {
  color: #3f729b !important;
}

.text-whatsapp {
  color: #43d854 !important;
}

.text-dribbble {
  color: #ea4c89 !important;
}

a {
  color: #3db16b;
  text-decoration: none;
  -webkit-transition: color 0.2s;
  -o-transition: color 0.2s;
  -moz-transition: color 0.2s;
  transition: color 0.2s;
}

a:not([href]):not([tabindex]) {
  color: #3db16b;
  text-decoration: none;
}

a.link-1 {
  color: black;
}

a.link-1:hover,
a.link-1:focus {
  color: #828282;
}

a.link-2 {
  color: black;
}

a.link-2:hover,
a.link-2:focus {
  color: #3db16b;
}

a:hover {
  text-decoration: none;
}

/* a:not(.active.list-group-item),
a:not(.btn):hover,
a:not(.btn):active,
a:not(.btn):focus {
  text-decoration: none !important;
  color: #000;
  outline: none;
} */

a.btn:hover,
a.btn:active,
a.btn:focus {
  text-decoration: none !important;
}

.btn {
  font-size: 14px;
  width: auto;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 10px 15px;
  line-height: 14px;
}

.btn[data-toggle='dropdown'] {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.btn.btn-sm {
  padding: 5px 10px;
  font-size: 13px;
}

.btn.btn-lg {
  padding: 15px 20px;
  font-size: 17px;
}

.btn.btn-block {
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.btn.btn-square {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.btn.btn-rounded {
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  padding: 10px 20px;
}

.btn.btn-rounded.btn-sm {
  padding: 5px 15px;
  font-size: 13px;
}

.btn.btn-rounded.btn-lg {
  padding: 20px 30px;
  font-size: 17px;
}

.btn.btn-floating {
  height: 35px;
  width: 35px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.btn.btn-floating.btn-sm {
  height: 30px;
  width: 30px;
}

.btn.btn-floating.btn-lg {
  height: 50px;
  width: 50px;
}

.btn.btn-primary,
a.btn[href='#next'],
a.btn[href='#previous'] {
  background: #3db16b;
  border-color: #3db16b;
}

.btn.btn-primary:not(:disabled):not(.disabled):hover,
a.btn:not(:disabled):not(.disabled):hover[href='#next'],
a.btn:not(:disabled):not(.disabled):hover[href='#previous'],
.btn.btn-primary:not(:disabled):not(.disabled):focus,
a.btn:not(:disabled):not(.disabled):focus[href='#next'],
a.btn:not(:disabled):not(.disabled):focus[href='#previous'],
.btn.btn-primary:not(:disabled):not(.disabled):active,
a.btn:not(:disabled):not(.disabled):active[href='#next'],
a.btn:not(:disabled):not(.disabled):active[href='#previous'] {
  background: #1f934d;
  border-color: #1f934d;
}

.btn.btn-primary:not(:disabled):not(.disabled):focus,
a.btn:not(:disabled):not(.disabled):focus[href='#next'],
a.btn:not(:disabled):not(.disabled):focus[href='#previous'] {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(61, 177, 107, 0.4) !important;
  -moz-box-shadow: 0 0 0 0.2rem rgba(61, 177, 107, 0.4) !important;
  box-shadow: 0 0 0 0.2rem rgba(61, 177, 107, 0.4) !important;
}

.btn.btn-primary.btn-pulse:not(:disabled):not(.disabled),
a.btn.btn-pulse:not(:disabled):not(.disabled)[href='#next'],
a.btn.btn-pulse:not(:disabled):not(.disabled)[href='#previous'] {
  -webkit-box-shadow: 0 0 0 0 rgba(61, 177, 107, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(61, 177, 107, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(61, 177, 107, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-primary:hover,
a.btn:hover[href='#next'],
a.btn:hover[href='#previous'] {
  -webkit-animation: none;
}

.btn.btn-gradient-primary {
  background: -webkit-linear-gradient(70deg, #65d993, #2ea25c);
  background: -moz- oldlinear-gradient(70deg, #65d993, #2ea25c);
  background: -o-linear-gradient(70deg, #65d993, #2ea25c);
  background: linear-gradient(20deg, #65d993, #2ea25c);
  border-color: transparent;
  color: white;
}

.btn.btn-secondary {
  background: dimgray;
  border-color: dimgray;
}

.btn.btn-secondary:not(:disabled):not(.disabled):hover,
.btn.btn-secondary:not(:disabled):not(.disabled):focus,
.btn.btn-secondary:not(:disabled):not(.disabled):active {
  background: #4b4b4b;
  border-color: #4b4b4b;
}

.btn.btn-secondary:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(105, 105, 105, 0.4) !important;
  -moz-box-shadow: 0 0 0 0.2rem rgba(105, 105, 105, 0.4) !important;
  box-shadow: 0 0 0 0.2rem rgba(105, 105, 105, 0.4) !important;
}

.btn.btn-secondary.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(105, 105, 105, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(105, 105, 105, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(105, 105, 105, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-secondary:hover {
  -webkit-animation: none;
}

.btn.btn-danger {
  background: #ea4141;
  border-color: #ea4141;
}

.btn.btn-danger:not(:disabled):not(.disabled):hover,
.btn.btn-danger:not(:disabled):not(.disabled):focus,
.btn.btn-danger:not(:disabled):not(.disabled):active {
  background: #cc2323;
  border-color: #cc2323;
}

.btn.btn-danger:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(234, 65, 65, 0.4) !important;
  -moz-box-shadow: 0 0 0 0.2rem rgba(234, 65, 65, 0.4) !important;
  box-shadow: 0 0 0 0.2rem rgba(234, 65, 65, 0.4) !important;
}

.btn.btn-danger.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(234, 65, 65, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(234, 65, 65, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(234, 65, 65, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-danger:hover {
  -webkit-animation: none;
}

.btn.btn-info {
  background: #00bcd4;
  border-color: #00bcd4;
}

.btn.btn-info:not(:disabled):not(.disabled):hover,
.btn.btn-info:not(:disabled):not(.disabled):focus,
.btn.btn-info:not(:disabled):not(.disabled):active {
  background: #009eb6;
  border-color: #009eb6;
}

.btn.btn-info:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.4) !important;
  -moz-box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.4) !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.4) !important;
}

.btn.btn-info.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(0, 188, 212, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(0, 188, 212, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(0, 188, 212, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-info:hover {
  -webkit-animation: none;
}

.btn.btn-light {
  background: #e6e6e6;
  border-color: #e6e6e6;
}

.btn.btn-light:not(:disabled):not(.disabled):hover,
.btn.btn-light:not(:disabled):not(.disabled):focus,
.btn.btn-light:not(:disabled):not(.disabled):active {
  background: #c8c8c8;
  border-color: #c8c8c8;
}

.btn.btn-light:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(230, 230, 230, 0.4) !important;
  -moz-box-shadow: 0 0 0 0.2rem rgba(230, 230, 230, 0.4) !important;
  box-shadow: 0 0 0 0.2rem rgba(230, 230, 230, 0.4) !important;
}

.btn.btn-light.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(230, 230, 230, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(230, 230, 230, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(230, 230, 230, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-light:hover {
  -webkit-animation: none;
}

.btn.btn-google {
  background: #db4437;
  border-color: #db4437;
  color: white;
}

.btn.btn-google:not(:disabled):not(.disabled):hover,
.btn.btn-google:not(:disabled):not(.disabled):focus,
.btn.btn-google:not(:disabled):not(.disabled):active {
  background: #bd2619;
  border-color: #bd2619;
}

.btn.btn-google:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(219, 68, 55, 0.4) !important;
  -moz-box-shadow: 0 0 0 0.2rem rgba(219, 68, 55, 0.4) !important;
  box-shadow: 0 0 0 0.2rem rgba(219, 68, 55, 0.4) !important;
}

.btn.btn-google.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(219, 68, 55, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(219, 68, 55, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(219, 68, 55, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-google:hover {
  -webkit-animation: none;
}

.btn.btn-twitter {
  background: #55acee;
  border-color: #55acee;
  color: white;
}

.btn.btn-twitter:not(:disabled):not(.disabled):hover,
.btn.btn-twitter:not(:disabled):not(.disabled):focus,
.btn.btn-twitter:not(:disabled):not(.disabled):active {
  background: #378ed0;
  border-color: #378ed0;
}

.btn.btn-twitter:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(85, 172, 238, 0.4) !important;
  -moz-box-shadow: 0 0 0 0.2rem rgba(85, 172, 238, 0.4) !important;
  box-shadow: 0 0 0 0.2rem rgba(85, 172, 238, 0.4) !important;
}

.btn.btn-twitter.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(85, 172, 238, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(85, 172, 238, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(85, 172, 238, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-twitter:hover {
  -webkit-animation: none;
}

.btn.btn-linkedin {
  background: #0077b5;
  border-color: #0077b5;
  color: white;
}

.btn.btn-linkedin:not(:disabled):not(.disabled):hover,
.btn.btn-linkedin:not(:disabled):not(.disabled):focus,
.btn.btn-linkedin:not(:disabled):not(.disabled):active {
  background: #005997;
  border-color: #005997;
}

.btn.btn-linkedin:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 119, 181, 0.4) !important;
  -moz-box-shadow: 0 0 0 0.2rem rgba(0, 119, 181, 0.4) !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 119, 181, 0.4) !important;
}

.btn.btn-linkedin.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(0, 119, 181, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(0, 119, 181, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(0, 119, 181, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-linkedin:hover {
  -webkit-animation: none;
}

.btn.btn-whatsapp {
  background: #43d854;
  border-color: #43d854;
  color: white;
}

.btn.btn-whatsapp:not(:disabled):not(.disabled):hover,
.btn.btn-whatsapp:not(:disabled):not(.disabled):focus,
.btn.btn-whatsapp:not(:disabled):not(.disabled):active {
  background: #25ba36;
  border-color: #25ba36;
}

.btn.btn-whatsapp:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(67, 216, 84, 0.4) !important;
  -moz-box-shadow: 0 0 0 0.2rem rgba(67, 216, 84, 0.4) !important;
  box-shadow: 0 0 0 0.2rem rgba(67, 216, 84, 0.4) !important;
}

.btn.btn-whatsapp.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(67, 216, 84, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(67, 216, 84, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(67, 216, 84, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-whatsapp:hover {
  -webkit-animation: none;
}

.btn.btn-instagram {
  background: #3f729b;
  border-color: #3f729b;
  color: white;
}

.btn.btn-instagram:not(:disabled):not(.disabled):hover,
.btn.btn-instagram:not(:disabled):not(.disabled):focus,
.btn.btn-instagram:not(:disabled):not(.disabled):active {
  background: #21547d;
  border-color: #21547d;
}

.btn.btn-instagram:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(63, 114, 155, 0.4) !important;
  -moz-box-shadow: 0 0 0 0.2rem rgba(63, 114, 155, 0.4) !important;
  box-shadow: 0 0 0 0.2rem rgba(63, 114, 155, 0.4) !important;
}

.btn.btn-instagram.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(63, 114, 155, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(63, 114, 155, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(63, 114, 155, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-instagram:hover {
  -webkit-animation: none;
}

.btn.btn-dribbble {
  background: #ea4c89;
  border-color: #ea4c89;
  color: white;
}

.btn.btn-dribbble:not(:disabled):not(.disabled):hover,
.btn.btn-dribbble:not(:disabled):not(.disabled):focus,
.btn.btn-dribbble:not(:disabled):not(.disabled):active {
  background: #cc2e6b;
  border-color: #cc2e6b;
}

.btn.btn-dribbble:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(234, 76, 137, 0.4) !important;
  -moz-box-shadow: 0 0 0 0.2rem rgba(234, 76, 137, 0.4) !important;
  box-shadow: 0 0 0 0.2rem rgba(234, 76, 137, 0.4) !important;
}

.btn.btn-dribbble.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(234, 76, 137, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(234, 76, 137, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(234, 76, 137, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-dribbble:hover {
  -webkit-animation: none;
}

.btn.btn-behance {
  background: #1769ff;
  border-color: #1769ff;
  color: white;
}

.btn.btn-behance:not(:disabled):not(.disabled):hover,
.btn.btn-behance:not(:disabled):not(.disabled):focus,
.btn.btn-behance:not(:disabled):not(.disabled):active {
  background: #004be1;
  border-color: #004be1;
}

.btn.btn-behance:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 105, 255, 0.4) !important;
  -moz-box-shadow: 0 0 0 0.2rem rgba(23, 105, 255, 0.4) !important;
  box-shadow: 0 0 0 0.2rem rgba(23, 105, 255, 0.4) !important;
}

.btn.btn-behance.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(23, 105, 255, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(23, 105, 255, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(23, 105, 255, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-behance:hover {
  -webkit-animation: none;
}

.btn.btn-apple,
.btn.btn-google-play {
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
}

.btn.btn-apple img,
.btn.btn-google-play img {
  width: 35px;
  margin-right: 10px;
}

.btn.btn-apple i,
.btn.btn-google-play i {
  font-size: 40px;
  margin-right: 10px;
}

.btn.btn-apple > span,
.btn.btn-google-play > span {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: left;
}

.btn.btn-apple > span span:nth-child(2),
.btn.btn-google-play > span span:nth-child(2) {
  font-size: 20px;
  font-weight: 600;
  margin-top: 5px;
}

.btn.btn-apple:hover,
.btn.btn-apple:active,
.btn.btn-apple:focus,
.btn.btn-google-play:hover,
.btn.btn-google-play:active,
.btn.btn-google-play:focus {
  background: #040507;
  color: white;
}

.btn.btn-apple {
  border: 1px solid #040507;
  color: #040507;
}

.btn.btn-google-play {
  background: #040507;
  color: white;
}

.btn.btn-google-play > span span:nth-child(1) {
  text-transform: uppercase;
  font-size: 12px;
}

.form-control {
  font-size: 14px;
}

.form-control {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.form-control:focus {
  border-color: transparent;
  -webkit-box-shadow: 0 0 0 0.05rem #3db16b;
  -moz-box-shadow: 0 0 0 0.05rem #3db16b;
  box-shadow: 0 0 0 0.05rem #3db16b;
}

.light-modal .modal-content {
  background-color: #ffffff !important;
  font-size: 14px;
  overflow: visible !important;
}

.light-modal .modal-content .modal-header {
  background-color: #f5f5f5 !important;
}

.light-modal .modal-content {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  overflow: hidden;
}

.assign-modal .modal-content {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  overflow: hidden;
  width: 410px;
}
.assign-body .modal-body {
  height: 550px;
  overflow-y: auto;
  width: 100%;
}

.light-modal .modal-content .modal-header {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px 30px;
  background-color: whitesmoke;
  border-bottom: none;
}
.assign-modal .modal-content .modal-header {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px 30px;
  background-color: white !important;
  border-bottom: 1px solid #dee2e6;
}
.assign-modal .modal-content .modal-title {
  font-style: normal;
  font-weight: 900 !important;
  font-size: 18px;
  color: #181c2f;
}

.light-modal .modal-content .modal-header .modal-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 13px;
  font-weight: 600;
}

.light-modal .modal-content .modal-header .modal-title i,
.light-modal .modal-content .modal-header .modal-title figure.avatar {
  margin-right: 0.7rem;
  font-size: 17px;
}

.light-modal .modal-content .modal-header .modal-title + button {
  text-shadow: none;
  font-size: 14px;
  margin: 0;
  background-color: #e1e1e1;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  padding: 0;
  width: 30px;
  height: 30px;
}

.light-modal .modal-content .modal-body {
  padding: 15px 30px 30px 30px;
}

.light-modal .modal-content .modal-footer {
  border-top: none;
  padding: 30px;
  padding-top: 0;
}
.assign-footer {
  border-top: 1px solid #dee2e6 !important;
  padding: 30px !important;
  padding-top: 25px !important;
  min-height: 106px;
}
.assign-footer .btn.disabled {
  background: #dbdbdb;
  border-radius: 5px;
  border-color: transparent !important;
}
.assign-footer-btn {
  background-color: #fff !important;
  color: #666666;
  border: 1px solid #c3c3c3;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
}
.assign-footer-btn:hover {
  color: #666666 !important;
  border: 1px solid #c3c3c3;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
}

.light-modal .modal-open .layout {
  -webkit-filter: blur(1px);
  -moz-filter: blur(1px);
  -o-filter: blur(1px);
  -ms-filter: blur(1px);
  filter: blur(1px);
}

.light-modal .modal.fade .modal-dialog.modal-dialog-zoom {
  -webkit-transform: translate(0, 0) scale(0.5);
  -moz-transform: translate(0, 0) scale(0.5);
  -ms-transform: translate(0, 0) scale(0.5);
  -o-transform: translate(0, 0) scale(0.5);
  transform: translate(0, 0) scale(0.5);
}

.light-modal .modal.show .modal-dialog.modal-dialog-zoom {
  -webkit-transform: translate(0, 0) scale(1);
  -moz-transform: translate(0, 0) scale(1);
  -ms-transform: translate(0, 0) scale(1);
  -o-transform: translate(0, 0) scale(1);
  transform: translate(0, 0) scale(1);
}

.light-modal .nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.light-modal .sms-header.nav-tabs .nav-link.active {
  color: #5fbc76;
  background-color: #fff;
  border: none !important;
  border-bottom: 1px solid #5fbc76 !important;
}

.light-modal .nav-tabs .nav-link:hover,
.light-modal .nav-tabs .nav-link:focus {
  border-color: #dee2e6;
  border-bottom-color: #dee2e6;
}

.light-modal .sms-header.nav-tabs .nav-link:hover {
  border: none;
}
.light-modal .sms-header.nav-tabs .nav-link:focus {
  border: none;
  border-bottom: 1px solid #5fbc76;
}

.light-modal .nav-tabs {
  border-bottom-color: #dee2e6;
}

.light-modal input[type='text'],
.light-modal textarea,
.light-modal select {
  background-color: #ffffff;
  /* color: #212529;
  border-color: #6e707a; */
}
.light-modal input[type='text']:disabled,
.light-modal textarea:disabled,
.light-modal select:disabled {
  border-color: hsl(0, 0%, 70%);
}

.light-modal input[type='text']:focus,
.light-modal textarea:focus,
.light-modal select:focus {
  color: #212529;
  background-color: #ffffff;
  border-color: #aaacb6;
}
.input-range__label--min {
  display: none;
}
.input-range__label--max {
  display: none;
}
.check-ring-state {
  display: flex;
  justify-content: center;
  align-items: center;
}

.layout-user {
  display: flex;
  height: 100vh;
}

.layout-user .content {
  flex: 1;
  margin-left: 70px;
  flex-direction: column;
  overflow: auto;
}

.initiating-call.modal-body {
  font-size: 20px;
  font-weight: 600;
}

.react-datetime-picker__wrapper {
  border-radius: 5px;
  height: 40px;
  width: 100%;
  padding: 0px 10px;
}
.custom-switch {
  z-index: 0;
}
