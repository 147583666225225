.header {
  padding: 60px;
  text-align: center;
  background: #1abc9c;
  color: white;
  font-size: 30px;
}

.right-dash-heading {
  padding: 19px 20px 18px;
  background: #5fbc76;
  border: none;
  color: #fff;
  display: flex;
}

.right-dash-heading > div {
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 600;
  margin-left: 12px;
  cursor: pointer;
}
.right-dash {
  width: 100%;
}

.right-dash-middle {
  padding: 60px 50px;
}

.dashboard-box {
  border: 1px solid #ddd;
  margin-bottom: 30px;
  background-color: #ffffff;
}
.dash-box-heading h3 {
  font-size: 18px;
  font-weight: 600;
  float: left;
}
.dash-box-heading span {
  float: right;
  font-size: 18px;
  font-weight: 600;
}
.dash-box-heading {
  border-bottom: 1px solid #ddd;
  padding: 15px 20px;
}
.dash-box-heading:after {
  content: '';
  display: block;
  clear: both;
}
.box-btm-cont {
  padding: 15px 20px;
}
.box-btm-cont p {
  margin-bottom: 0;
}

.dashboard-chart {
  border: 1px solid #ddd;
  padding: 15px 20px;
  background-color: #ffffff;
}
.dash-fix-content {
  padding: 0 20px;
  margin-top: 20px;
  text-align: center;
  position: absolute;
  width: 100%;
}
.right-dash {
  position: relative;
}
