.contact-name {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
}
.liststwo {
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
  padding: 8px;
}

.infoname {
  margin-left: 10px;
}

.grpbox {
  padding-inline: 8px;
  padding-block: 4px;
  border: 1px solid #9ca3af;
  border-radius: 16px;
  display: flex;
  align-items: center;
}

.boxName {
  margin: 0px;
  margin-left: 4px;
  font-size: 12px;
  color: #9ca3af;
}

.main {
  display: flex;
  align-items: flex-start;
  flex: 1;
  padding: 30px;
}
.card-head {
  display: flex;
  align-items: flex-start;
  padding: 20px 15px 0px 15px;
}

.img {
  border-radius: 50%;
  object-fit: contain;
}

.titleDiv {
  margin-left: 15px;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.detail-name {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
}

.chipsDiv {
  display: flex;
  align-items: center;
  flex: 1;
}

.chipsinnerDiv {
  display: flex;
  align-items: center;
  flex: 1;
  text-align: center;
}

.displaychips {
  margin-right: 8px;
  padding: 2px;
  text-align: center;
}

.popBox {
  width: 26px;
  height: 26px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnEdit {
  background-color: #22c55e;
  color: white;
  padding-inline: 12px;
  padding-block: 4px;
  border-radius: 4px;
  border: none;
  outline: none;
}

.headerTitle {
  font-size: 20px;
  font-weight: 700;
  margin: 10px;
  margin-left: 0px;
}

.contactList {
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
  margin: 10px;
  margin-left: 0px;
}

.contactDetailContainer {
  padding-left: 10px;
}

.vertical {
  border-right: 1px solid #d1d5db;
  height: 435px;
  overflow-x: hidden;
}

.recent-msg {
  height: 435px;
  overflow-x: hidden;
}

.data {
  font-size: 10px;
  font-weight: 300;
  /* margin-left: 20px; */
}
.lists {
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
  margin-left: 20px;
  margin: 10px;
}

.cardTitle {
  font-size: 14px;
  font-weight: 600;
  padding-left: 1%;
}

.contactDetailContainer .headerTitle {
  margin-bottom: 25px;
}

.listValue {
  font-size: 15px;
  font-weight: 400;
  padding-left: 20px;
  padding: 0px 25px 25px;
  margin: 10px;
}

.lable {
  margin: 8;
  margin-left: 4;
  font-size: 12;
  color: #9ca3af;
}

.boxdata {
  height: 12px;
  width: 20px;
  border-radius: 8px;
}

.right-middle-data {
  padding: 30px 30px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.bodyData {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 30px;
}

.bodyData .container {
  padding: 0 5px;
  max-width: 100%;
}

.listvaluedata {
  margin: 5px;
}
.checkboxInput {
  margin: 5px;
  padding: 10px;
}

.checkbox:checked:before {
  background-color: red;
}

.maindata {
  background-color: red;
}

.errorMsg {
  padding-left: 12px;
}

.colData {
  padding-left: 0px;
  padding-right: 5px;
}

.colMsgData {
  padding-left: 0px;
  padding-right: 15px;
}
.customized{
  flex: 19%;
  max-width: 100%;
}
.notePrivate{
  margin: 6px;
}

.checkboxstyle {
  margin: 6px;
  padding: 10px;
}

.drop-distributionData {
  border-bottom: 1px solid #ddd;
  display: flex;
  margin: 12px 16px;
}

.profileimgs {
  border-radius: 50%;
  object-fit: contain;
}

.dist-badge-data {
  width: 13%;
  display: block !important;
  padding: 10px !important;
  margin-right: 10px !important;
  border-radius: 7px !important;
}
.drop-checkbox {
  width: auto !important;
  margin: 6px;
  padding: 10px;
}

.backArrowBtn {
  margin: 10px;
  background-color: #22c55e;
  border: none;
  color: white;
  padding-inline: 12px;
  padding-block: 4px;
  border-radius: 4px;
  border: none;
  outline: none;
  cursor: pointer;
  width: auto;
}

.street {
  display: flex;
}
.addressContact {
  margin-top: -5px;
  padding-left: 5px;
}

.addressList {
  margin: 0 0 0 0;
}
