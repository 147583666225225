.layout-admin {
  display: flex;
  height: 100vh;
}

.layout-admin .content {
  flex: 1;
  margin-left: 70px;
  flex-direction: column;
  overflow: auto;
  display: flex;
}

.header {
  padding: 60px;
  text-align: center;
  background: #1abc9c;
  color: white;
  font-size: 30px;
}

.right-dash-heading {
  padding: 19px 20px 18px;
  background: #5fbc76;
  border: none;
  color: #fff;
  display: flex;
}

.right-dash-heading > div {
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 600;
  margin-left: 12px;
  cursor: pointer;
}
.right-dash {
  width: 100%;
}

.right-dash-middle {
  padding: 50px 50px 0px !important;
}
.right-dash-data{
  display: flex;
  flex-direction: column;
  flex:1;
}

.dashboard-box {
  border: 1px solid #ddd;
  margin-bottom: 30px;
  background-color: #ffffff;
}
.dash-box-heading h3 {
  font-size: 1.45rem !important;
  font-weight: bold !important;
  float: left;
}
.dash-box-heading span {
  float: none !important;
  font-size: 12px;
  font-weight: 500;
  display: block;
}
@media screen and (min-width: 1200px) {
  .dash-box-heading {
    border-bottom: none !important;
    padding: 0px 20px !important;
    display: flex !important;
  }
  .dash-box-heading h3 {
    font-size: 1.45rem !important;
    font-weight: bold !important;
    float: left;
  }
  .dashboard-user {
    margin-bottom: 0;
    margin-left: 50px;
    font-weight: bold;
    font-size: 20px;
    margin-top: 0px;
  }
}
@media screen and (min-width: 1100px) and (max-width: 1199px) {
  .dash-box-heading h3 {
    font-size: 18px !important;
    margin-right: 5px;
  }
  .dashboard-user {
    margin-left: 17px !important;
    margin-top: -2px !important;
    font-size: 17px !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1099px) {
  .dash-box-heading {
    border-bottom: none !important;
    padding: 10px 8px !important;
    display: flex;
  }
  .dash-box-heading h3 {
    font-size: 16px !important;
  }
  .dashboard-user {
    margin-left: 12px !important;
    margin-top: -2px !important;
    font-size: 14px !important;
  }
  .account-data {
    font-size: 10px !important;
    margin-top: 4px !important;
  }
}

.dash-box-account {
  border-bottom: none !important;
  padding: 0px 0px;
}

.dash-box-account span {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  margin-right: 10px;
}
.admin-users {
  padding: 0 !important;
}
.dash-box-heading:after {
  content: '';
  display: block;
  clear: both;
}
.box-btm-cont {
  padding: 15px 20px;
}
.box-btm-cont p {
  margin-bottom: 0;
}

.dashboard-chart {
  border: 1px solid #ddd;
  padding: 15px 20px;
  background-color: #ffffff;
}
.dash-fix-content {
  padding: 0 20px;
  margin-top: 20px;
  text-align: center;
  position: absolute;
  width: 100%;
}
.right-dash {
  position: relative;
}
.account-data {
  font-size: 12px;
  font-weight: normal;
  margin-left: 6px;
  margin-bottom: 0;
  margin-top: 2px;
}
.dashboard-user {
  margin-bottom: 0;
  margin-left: 50px;
  font-weight: bold;
  font-size: 20px;
  margin-top: 0px;
}
.dash-row {
  display: flex;
  align-items: center;
  background-color: #82828282;
  border-bottom: 1px solid #ddd;
}
.account-col {
  padding-left: 0;
  padding-right: 0;
}
.dash-footer {
  margin-left: 0;
  padding: 20px;
  margin-right: 0px;
  background-color: #82828282;
}
.number-data h3 {
  font-weight: bold;
  font-size: 1.45rem;
}
.sms-sender {
  background-color: #82828282;
  padding: 20px;
}
.sms-sender h3 {
  font-weight: bold;
  font-size: 1.45rem;
}
.chart-data {
  margin-bottom: 10px;
}
.account-id {
  width: 60%;
}
