.indexedStep {
  color: white;
  width: 20px;
  height: 20px;
  font-size: 12px;
  background-color:#3db16b;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.indexedStep.accomplished {
  background-color: #3db16b;
}
