.layout-user {
  display: flex;
  height: 100vh;
}

.layout-user .content {
  flex: 1;
  margin-left: 70px;
  flex-direction: column;
  overflow: auto;
  display: flex;
}

.user-body {
  margin: 10px;
}

.Unread-message {
  padding: 100px;
  font-size: 29px;
  cursor: pointer;
}

.call-devices {
  list-style: none;
  display: flex;
  margin: 10px;
  font-size: 20px;
  justify-content: center;
}


.call-Desk-count {
  color: blue;
}
.call-Soft-count {
  color: gold;
}

.call-des{
  /* padding: 20px; */
  margin: 20px;
  font-size: 15px;
}


.voice-mail-box{
  cursor: pointer;
  padding: 35px;
}
.CallDevices-box {
  border: 1px solid #ddd;
  /* margin-bottom: 30px; */
  background-color: #ffffff;
  height: 97%;
}

.PhoneCalls-box{
  border: 1px solid #ddd;
  background-color: #ffffff;
  height:97%;
}


.pie-data{
  display: flex;
  align-items: center;
}


.voice-main{
    display: flex;
    flex-direction: column;
    gap: 8;
}


.voice-card{
  display: flex;
  align-items: center;
  gap: 4;
}
.no-voice-mail-div{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 273px;
}

.no-data-text{
  font-size: 20px;
}

.textcall{
    padding-left: 13px;
}


